<!--
 * @Description: 德佑人员（策略）
 * @Author: 琢磨先生
 * @Date: 2023-02-28 22:18:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-06-12 15:21:38
-->
<template>
  <el-card class="box query">
    <el-form
      :model="query"
      ref="query"
      :inline="true"
      @submit.prevent="onSearch"
    >
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="姓名、手机号"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          round
          @click="onSearch"
          icon="search"
          native-type="submit"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="actions"></div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column label="ID"  fixed="left" prop="id" width="80"></el-table-column>
      <el-table-column
        label="姓名"
        prop="name"
        min-width="150"
        fixed="left"
      ></el-table-column>
      <el-table-column label="手机" prop="mobile" width="150"></el-table-column>
      <el-table-column
        label="公司"
        prop="companyName"
        min-width="250"
        v-if="!isFromCompany"
      ></el-table-column>
      <el-table-column label="性别" prop="sex" width="100"></el-table-column>
      <el-table-column label="状态" prop="enabled" width="120">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.disabled">禁用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="开放" width="100">
        <template #default="scope">
          <el-link type="success" :underline="false" v-if="scope.row.isOpen"
            >是</el-link
          >
          <el-link type="danger" :underline="false" v-else>未开放</el-link>
        </template>
      </el-table-column>
      <el-table-column label="策略" min-width="300">
        <template #default="scope">
          {{ scope.row.power_names.join('、') }}
        </template>
      </el-table-column>

      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >权限策略</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <set-power
    :item="current_item" 
    @success="onSuccess"
    @closed="current_item = null"
  ></set-power>
</template>

<script>
import SetPower from "./set_power.vue";
export default {
  components: {
    SetPower,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "",
          prop: "",
        },
        q: "",
        company_name: "",
        company_id: 1,
        isDel: false,
        showPower: true,
      },
      avatarUrl: require("@/assets/avatar.png"),
      tableData: {
        counts: 0,
      },
      // isNotCompany: false,
      current_item: null,
      is_can_edit: false,
      is_can_del: false,
      is_can_reset: false,
      is_can_logout: false,
      is_can_open: false,
    };
  },
  // props: ["isFromCompany", "companyName"],
  props: {
    isFromCompany: Boolean,
    // companyId: Number,
    // companyName: String,
    company: Object,
  },
  watch: {
    isFromCompany: {
      handler() {
        // this.isNotCompany = !this.isFromCompany;
      },
      immediate: true,
    },
    // companyName: {
    //   handler() {
    //     this.query.company_name = "";
    //     if (this.companyName) {
    //       this.query.company_name = this.companyName;
    //     }
    //   },
    //   immediate: true,
    // },
    company: {
      handler() {
        this.query.company_name = "";
        if (this.company) {
          this.query.company_name = this.company.companyName;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/consumer/edit");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      //   this.current = null;
      this.loading = true;
      this.$http
        .post("/admin/v1/consumer", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.onSearch();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 修改
     */
    onEdit(item) {
      this.current_item = item;
    },

    /**
     *
     */
    onSuccess() {
      this.current_item = null;
      this.loadData();
    },
  },
};
</script>

<style scoped></style>
