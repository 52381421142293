<!--
 * @Description: 配置人员权限策略
 * @Author: 琢磨先生
 * @Date: 2024-06-12 10:53:33
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-06-12 11:41:37
-->
<template>
  <el-drawer
    v-model="drawer"
    title="配置人员权限策略"
    size="800px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @closed="onClosed"
  >
    <el-descriptions title="" border v-if="item">
      <el-descriptions-item label="公司">{{
        item.companyName
      }}</el-descriptions-item>
      <el-descriptions-item label="姓名">{{ item.name }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        item.mobile
      }}</el-descriptions-item>
    </el-descriptions>
    <el-divider>权限策略</el-divider>
    <div class="power_wrp">
      <el-scrollbar>
        <el-tree
          ref="tree"
          :data="powers"
          show-checkbox
          node-key="id"
          default-expand-all
          check-strictly
        ></el-tree>
      </el-scrollbar>
    </div>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-drawer>
</template>

<script>
import power_api from "@/http/power_api";

export default {
  data() {
    return {
      drawer: false,
      saving: false,
      powers: [],
      form: {},
    };
  },
  emits: ["success", "closed"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          if (this.item.id) {
            this.loadDtl();
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadPower();
  },
  methods: {
    /**
     * 关闭时清除所有选择
     */
    onClosed() {
      this.$refs.tree.setCheckedKeys([], false);
    },

    /**
     *  加载详情
     */
    loadDtl() {
      this.$http
        .get("admin/v1/consumer/power?id=" + this.item.id)
        .then((res) => {
          if (res.code == 0) {
            this.form.powerIds = res.data;
            this.$refs.tree.setCheckedKeys(res.data, true);
          }
        });
    },

    /**
     * 打开权限设置
     */
    openSetPower() {},
    /**
     * 加载权限策略
     */
    loadPower() {
      power_api.get_tree(this.$cfg.AGENT_CODE).then((res) => {
        if (res.code == 0) {
          this.powers = res.data;
        }
      });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.saving = true;

      var model = {
        id: this.item.id,
        powerIds: this.$refs.tree.getCheckedNodes().map((x) => x.id),
      };

      this.$http
        .post("admin/v1/consumer/power/set", model)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.drawer = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$emit("success");
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
<style scoped>
.power_wrp {
  flex: 1;
  height: 1px;
}
</style>
